<template lang="pug">
  #gladly-help-center
</template>
<script>
import { mapState } from 'vuex';

export default {
  name: 'HelpCenter',

  computed: {
    ...mapState('global', ['MRConfig']),
  },

  mounted() {
    window.gladlyHCConfig = this.MRConfig.gladlyHelpCenter;
    this.addJsScript('https://cdn.gladly.com/help-center/hcl.js', {async: true});
  },

  methods: {
    addJsScript(scriptUrl,  config) {
      return new Promise((resolve) => {
        let head = document.getElementsByTagName('head')[0];
      
        let script = document.createElement('script');
        script.setAttribute('type', 'text/javascript');
        script.setAttribute('src', scriptUrl);

        if (config) {
          Object.keys(config).forEach(key => {
            script.setAttribute(key, config[key]);
          });
        }

        script.onload = resolve;

        head.appendChild(script);
      });
    },
  },
};
</script>
<style lang="stylus">
#gladly-help-center
  max-width bp-desktop-max
  padding-bottom 1em

  @media only screen and (max-width: 1299px)
    padding 1em
  
  .gladlyHC-searchContainer
    padding 3% 10%
    
    @media mq-mobile
      padding 3%

    .gladlyHC-searchInput
      padding 1%
      
      @media mq-mobile
        padding 2.5%

    .gladlyHC-searchMenuContainer
      .gladlyHC-searchMenu
        padding 1%

        .gladlyHC-searchResult
          margin 0.25em 0em

          &:last
            margin-bottom 0em

  .gladlyHC-faqHeading
  .gladlyHC-searchResultsSection-heading
  .gladlyHC-answerDetail-name
    text-align center
    padding-bottom 2em
    font-family 'Averta-Bold', sans-serif
    font-weight normal
    font-style normal
    
    @media only screen and (max-width: 1299px)
      padding 1em
  
  .gladlyHC-searchResultsSection-results
  .gladlyHC-answersIndex-list
    .gladlyHC-searchResultsSection-result
    .gladlyHC-answersIndex-item
      margin-bottom 1em
    
    .gladlyHC-answersIndex-item:first
    .gladlyHC-searchResultsSection-result:first
      margin-top 1em

  .gladlyHC-faqContainer
    .gladlyHC-faqSections
      .gladlyHC-faqSection
        padding 0% 2%
        margin-bottom 32px
        max-width 50%

        @media mq-mobile
          max-width 100%
        
        .gladlyHC-faqSection-header
          font-family 'Averta-Bold', sans-serif
          font-weight normal
          font-style normal
          margin-bottom 12px

        .gladlyHC-faqSection-list
          .gladlyHC-faqSection-listItem
            background-color ui-color-3
            padding 12px
            margin-bottom 8px
          
  .gladlyHC-searchResultsContainer
  .gladlyHC-answerDetail
    padding-top 2em
</style>